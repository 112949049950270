import { EntityData } from "@/components/TriggerSetup/logic/types/entity-data.type";
import store from "@/store/store";
import { ConditionsWrapperType } from "../../logic/types/types";
import BanLevelCountConditionsWrapper from "./BanLevelCountConditionsWrapper.vue";
import WarnCountUpper from "./conditions/BanLevelCountUpper";
import WarnCountEquals from "./conditions/BanLevelCountEquals";
import WarnCountLower from "./conditions/BanLevelCountLower";

import { ADVANCED_TAG } from "@/includes/constants";
import { entityDataTitleKeyFields } from "@/components/TriggerSetup/logic/helpers";

const condition: EntityData = {
  'setupView': BanLevelCountConditionsWrapper,
  ...entityDataTitleKeyFields(ConditionsWrapperType.BanLevelCountConditionsWrapper, 'trigger_condition_'),
  structure: WarnCountEquals.structure,
  extraProps: {
    conditions: [
      WarnCountUpper,
      WarnCountEquals,
      WarnCountLower,
    ],
  },
  limited: () => store.getters.isChatSet && (!store.getters.isChatLicenseExists || !store.getters.isChatAtLeastAdvanced) ? ADVANCED_TAG : null
}

export default condition
