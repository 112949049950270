

















































































































































































































import ActionTargets from "@/components/TriggerSetup/components/ActionTargets/ActionTargets.vue";
import ActionChance from "@/components/TriggerSetup/components/ActionChance/ActionChance.vue";
import { ActionSetupView } from "@/components/TriggerSetup/logic/mixins/action-setup-view.mixin";
import { InputSetups } from "@/mixins/input-setups";
import LlmConditionsMixin from "@/components/TriggerSetup/logic/mixins/LlmConditionsMixin";
import Placeholders from "@/mixins/placeholders/placeholders";

import { MediaEditorMode } from "piramis-base-components/src/components/NewMessageEditors/types";
import MultiMessageEditorWithMediaInput from "piramis-base-components/src/components/Pi/fields/NewMultiMessageEditorWithMediaInput/MultiMessageEditorWithMediaInput.vue";
import {
  IMessageTemplate
} from "piramis-base-components/src/components/NewMessageEditors/NewMessageEditorWithMedia/types";
import { IterableItemFactory, IterableListItem } from "piramis-base-components/src/shared/utils/IterableItemFactory";
import ConfigField from "piramis-base-components/src/components/ConfigField/ConfigField.vue";

import { Component, Mixins, Watch } from 'vue-property-decorator'
import { ValidationObserver } from "vee-validate";
import { cloneDeep } from "lodash";

const iterableItemFactory = new IterableItemFactory()

type VariantItem =  { key: string, message: Array<IMessageTemplate> }

@Component({
  data() {
    return {
      MediaEditorMode
    }
  },
  'components': {
    ActionTargets,
    ActionChance,
    MultiMessageEditorWithMediaInput,
    ValidationObserver,
    ConfigField
  }
})
export default class LlmSwitchRequestActionSetupView extends Mixins(ActionSetupView, LlmConditionsMixin, InputSetups, Placeholders) {
  isAddVariantModalOpen = false

  variant: IterableListItem<VariantItem> = this.baseVariantModel()

  frontendVariants: Array<IterableListItem<VariantItem>> = []

  @Watch('frontendVariants', { deep: true })
  frontendVariantsChanged(variants: Array<IterableListItem<VariantItem>>) {
    this.model.variants = this.getRawVariants(variants)
  }

  get currentVariantExists() {
    return this.frontendVariants.findIndex(v => v.guid === this.variant.guid) !== -1
  }

  get modalOkText() {
    if (this.currentVariantExists) {
      return this.$t('save_button').toString()
    } else {
      return this.$t('add').toString()
    }
  }

  getFirstMessageText(message: VariantItem['message']) {
    if (message.length) {
      const [ first ] = message
      const { text } = first

      if (text.trim()) {
        return text.trim()
      }
    }

    return ''
  }

  okClick() {
    if (this.currentVariantExists) {
      const idx = this.frontendVariants.findIndex(v => v.guid === this.variant.guid)

      if (idx !== -1) {
        this.frontendVariants.splice(idx, 1, this.variant)
      }
    } else {
      this.frontendVariants.push(this.variant)
    }

    this.isAddVariantModalOpen = false
  }

  baseVariantModel(): IterableListItem<VariantItem> {
    return iterableItemFactory.create({
      key: '',
      message: [  ]
    })
  }

  editVariant(variant: IterableListItem<VariantItem>) {
    this.variant = cloneDeep(variant)

    this.isAddVariantModalOpen = true
  }

  removeVariant(guid: string) {
    this.frontendVariants = this.frontendVariants.filter(v => v.guid !== guid)
  }

  getFrontendVariants(variants: Record<string, Array<IMessageTemplate>>): Array<IterableListItem<VariantItem>> {
    return Object.entries(variants).map(([ key, message ]) => {
      return iterableItemFactory.create({
        key,
        message
      })
    })
  }

  getRawVariants(variants: Array<IterableListItem<VariantItem>>) {
    return variants.reduce<Record<string, Array<IMessageTemplate>>>((acc, value) => {
      const { key, message } = value.value

      if (key && message.length) {
        acc[value.value.key] = value.value.message
      }

      return acc
    }, {})
  }

  mounted() {
    this.frontendVariants = this.getFrontendVariants(this.model.variants ?? {})
  }
}
