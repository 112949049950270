import { EntityData } from "@/components/TriggerSetup/logic/types/entity-data.type";
import { ConditionsWrapperType } from "../../logic/types/types";
import RankConditionsWrapper from "./RankConditionsWrapper.vue";
import RankUpper from "./conditions/RankUpper";
import RankEquals from "./conditions/RankEquals";
import RankLower from "./conditions/RankLower";
import { entityDataTitleKeyFields } from "@/components/TriggerSetup/logic/helpers";

const condition: EntityData = {
  'setupView': RankConditionsWrapper,
  ...entityDataTitleKeyFields(ConditionsWrapperType.RankConditionsWrapper, 'trigger_condition_'),
  structure: RankEquals.structure,
  extraProps: {
    conditions: [
      RankEquals,
      RankLower,
      RankUpper,
    ],
  }

}

export default condition
