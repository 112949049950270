import { ConditionsWrapperType } from "../../logic/types/types";
import UserLoginMinLen from "./conditions/UserLoginMinLen";
import UserLoginConditionsWrapper from "./UserLoginConditionsWrapper.vue";

import UserLoginMaxLen from "./conditions/UserLoginMaxLen";
import { EntityData } from "@/components/TriggerSetup/logic/types/entity-data.type";
import { entityDataTitleKeyFields } from "@/components/TriggerSetup/logic/helpers";

const condition: EntityData = {
  'setupView': UserLoginConditionsWrapper,
  ...entityDataTitleKeyFields(ConditionsWrapperType.UserLoginConditionsWrapper, 'trigger_condition_'),
  structure: UserLoginMinLen.structure,
  extraProps: {
    conditions: [
      UserLoginMinLen,
      UserLoginMaxLen,
    ],
  }

}

export default condition
