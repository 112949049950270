import { EntityData } from "@/components/TriggerSetup/logic/types/entity-data.type";
import store from "@/store/store";
import { ConditionsWrapperType } from "../../logic/types/types";
import XpConditionsWrapper from "./XpConditionsWrapper.vue";
import XpUpper from "./conditions/XpUpper";
import XpEquals from "./conditions/XpEquals";
import XpLower from "./conditions/XpLower";

import { ADVANCED_TAG } from "@/includes/constants";
import { entityDataTitleKeyFields } from "@/components/TriggerSetup/logic/helpers";

const condition: EntityData = {
  'setupView': XpConditionsWrapper,
  ...entityDataTitleKeyFields(ConditionsWrapperType.XpConditionsWrapper, 'trigger_condition_'),  structure: XpEquals.structure,
  extraProps: {
    conditions: [
      XpEquals,
      XpLower,
      XpUpper,
    ],
  },
  limited: () => {
    if (store.getters.isChatSet && store.getters.isChatLicenseExists && store.getters.isChatAtLeastAdvanced) {
      return null
    } else {
      return ADVANCED_TAG
    }
  }
}

export default condition
