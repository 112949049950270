import { EntityData } from "@/components/TriggerSetup/logic/types/entity-data.type";
import { ActionsType } from "@/components/TriggerSetup/logic/types/types";
import { entityDataTitleKeyFields } from "@/components/TriggerSetup/logic/helpers";
import LlmConvertRequestActionSetupView from "./LlmConvertRequestActionSetupView.vue";

const action: EntityData = {
  'setupView': LlmConvertRequestActionSetupView,
  'structure': {
    'type': ActionsType.LlmConvertRequestAction,
    'target': "Caller",
    'chance': 100,
    'credential': '',
    'model': '',
    'instruction': '',
    'request': '{message.start(2)}',
    'temperature': 0,
    'maxTokens': 300,
    'asReply': 'Reply',
    'message': [ {
      'text': '%ai_response_text%',
      'remove_after': 0,
      'send_after': 0,
      'pin': false,
      'remove_previous': true,
      'disable_link_preview': false,
      'disable_notify': false,
      'protect_content': false,
      'topics': [],
      'attachments': [],
      'buttons': [],
    } ]
  },
  ...entityDataTitleKeyFields(ActionsType.LlmConvertRequestAction, 'trigger_action_'),
}

export default action
