import { EntityData } from "@/components/TriggerSetup/logic/types/entity-data.type";
import { newBaseCondition } from "@/components/TriggerSetup/logic/structures/base-condition.structure";
import { ConditionsType, ConditionZone, TriggerCondition } from "@/components/TriggerSetup/logic/types/types";
import { entityDataTitleKeyFields } from "@/components/TriggerSetup/logic/helpers";
import WorkDaysSetupView from "./WorkDaysSetupView.vue";

const condition: EntityData = {
  'setupView': WorkDaysSetupView,
  'structure': {
    'condition_type': ConditionsType.WorkDays,
    ...newBaseCondition,
    ...{ 'value': [ 1, 30 ] }
  } as TriggerCondition,
  ...entityDataTitleKeyFields(ConditionsType.WorkDays, 'trigger_condition_'),
  extraProps: {
    possibleConditionZone: [ ConditionZone.Common ]
  }
}

export default condition
