import { EntityData } from "@/components/TriggerSetup/logic/types/entity-data.type";
import store from "@/store/store";

import { chain, cloneDeep } from 'lodash';

import MessageReplyTypeNew from './MessageReplyType'
import MessageTypeNew from './MessageType'
import CallerTypeNew from './CallerType'
import WorkDatesNew from './WorkDates'
import WorkMonthDaysNew from './WorkMonthDays'
import WorkDaysNew from './WorkDays'
import WorkTimeNew from './WorkTime'
import WorkWeekDaysNew from './WorkWeekDays'
import ThreadIdNew from "./ThreadId";
import RegexpConditionsWrapperNew from './RegexpConditionsWrapper';
import XpConditionsWrapperNew from "./XpConditionsWrapper";
import MessageCountConditionsWrapperNew from './MessageCountConditionsWrapper'
import MessageTextConditionsWrapperNew from "./MessageTextConditionsWrapper";
import MessageTextLenConditionsWrapperNew from "./MessageTextLenConditionsWrapper";
import MessageWordConditionsWrapperNew from "./MessageWordConditionsWrapper";
import UserLoginConditionsWrapperNew from './UserLoginConditionsWrapper'
import UserNameConditionsWrapperNew from './UserNameConditionsWrapper'
import RankConditionsWrapperNew from "./RankConditionsWrapper";
import ActionPointsConditionsWrapperNew from "./ActionPointsConditionsWrapper";
import ReputationConditionsWrapperNew from "./ReputationConditionsWrapper";
import FirstUserMessageTimeConditionsWrapperNew from "./FirstUserMessageTimeConditionsWrapper";
import FirstUserEnterTimeConditionsWrapperNew from "./FirstUserEnterTimeConditionsWrapper";
import LastUserEnterTimeConditionsWrapperNew from "./LastUserEnterTimeConditionsWrapper";
import FirstUserActivityConditionsWrapperNew from "./FirstUserActivityConditionsWrapper";
import CheckBindedChannelNew from "./CheckBindedChannelWrapper/conditions/CheckBindedChannel";
import CheckBoostNew from "./CheckBoostWrapper/conditions/CheckBoost";
import CheckPremiumNew from "./CheckPremiumWrapper/conditions/CheckPremium";
import UserInformationNew from "./UserInformationConditionsWrapper/conditions/UserInformation";
import MessageTextEndWithNew from "./MessageTextConditionsWrapper/conditions/MessageTextEndWith";
import MessageTextFullMatchNew from "./MessageTextConditionsWrapper/conditions/MessageTextFullMatch";
import MessageTextStartWithNew from "./MessageTextConditionsWrapper/conditions/MessageTextStartWith";
import MessageTextRegExpNew from "./RegexpConditionsWrapper/conditions/MessageTextRegExp";
import MessageMentionEntityTypeNew from "./RegexpConditionsWrapper/conditions/MessageMentionEntityType";
import UserLoginRegExpNew from "./RegexpConditionsWrapper/conditions/UserLoginRegExp";
import UserNameRegExpNew from "./RegexpConditionsWrapper/conditions/UserNameRegExp";
import MessagePhoneNumberEntityTypeNew from "./MessagePhoneNumberEntityType";
import MessageTextMentionEntityTypeNew from "./RegexpConditionsWrapper/conditions/MessageTextMentionEntityType";
import XpEqualsNew from "./XpConditionsWrapper/conditions/XpEquals";
import XpLowerNew from "./XpConditionsWrapper/conditions/XpLower";
import XpUpperNew from "./XpConditionsWrapper/conditions/XpUpper";
import MessageCountLowerNew from "./MessageCountConditionsWrapper/conditions/MessageCountLower";
import MessageCountUpperNew from "./MessageCountConditionsWrapper/conditions/MessageCountUpper";
import DaysWithMessagesConditionsWrapperNew from "./DaysWithMessagesConditionsWrapper";
import DaysWithMessagesLowerNew from "./DaysWithMessagesConditionsWrapper/conditions/DaysWithMessagesLower";
import DaysWithMessagesUpperNew from "./DaysWithMessagesConditionsWrapper/conditions/DaysWithMessagesUpper";
import MessageTextMaxLenNew from "./MessageTextLenConditionsWrapper/conditions/MessageTextMaxLen";
import MessageTextMinLenNew from "./MessageTextLenConditionsWrapper/conditions/MessageTextMinLen";
import MessageWordFullMatchNew from "./MessageWordConditionsWrapper/conditions/MessageWordFullMatch";
import MessageWordEndWithNew from "./MessageWordConditionsWrapper/conditions/MessageWordEndWith";
import MessageWordStartWithNew from "./MessageWordConditionsWrapper/conditions/MessageWordStartWith";
import UserLoginMinLenNew from "./UserLoginConditionsWrapper/conditions/UserLoginMinLen";
import UserLoginMaxLenNew from "./UserLoginConditionsWrapper/conditions/UserLoginMaxLen";
import UserNameMinLenNew from "./UserNameConditionsWrapper/conditions/UserNameMinLen";
import UserNameMaxLenNew from "./UserNameConditionsWrapper/conditions/UserNameMaxLen";
import RankEqualsNew from "./RankConditionsWrapper/conditions/RankEquals";
import RankLowerNew from "./RankConditionsWrapper/conditions/RankLower";
import RankUpperNew from "./RankConditionsWrapper/conditions/RankUpper";
import ActionPointsEqualsNew from "./ActionPointsConditionsWrapper/conditions/ActionPointsEquals";
import ActionPointsLowerNew from "./ActionPointsConditionsWrapper/conditions/ActionPointsLower";
import ActionPointsUpperNew from "./ActionPointsConditionsWrapper/conditions/ActionPointsUpper";
import FirstUserMessageTimeMoreThatNew
  from "./FirstUserMessageTimeConditionsWrapper/conditions/FirstUserMessageTimeMoreThat";
import FirstUserMessageTimeLowerThatNew
  from "./FirstUserMessageTimeConditionsWrapper/conditions/FirstUserMessageTimeLowerThat";
import FirstUserEnterTimeLowerThatNew
  from "./FirstUserEnterTimeConditionsWrapper/conditions/FirstUserEnterTimeLowerThat";
import FirstUserEnterTimeMoreThatNew
  from "./FirstUserEnterTimeConditionsWrapper/conditions/FirstUserEnterTimeMoreThat";
import LastUserEnterTimeLowerThatNew
  from "./LastUserEnterTimeConditionsWrapper/conditions/LastUserEnterTimeLowerThat";
import LastUserEnterTimeMoreThatNew
  from "./LastUserEnterTimeConditionsWrapper/conditions/LastUserEnterTimeMoreThat";
import FirstUserActivityLowerThatNew
  from "./FirstUserActivityConditionsWrapper/conditions/FirstUserActivityLowerThat";
import FirstUserActivityMoreThatNew
  from "./FirstUserActivityConditionsWrapper/conditions/FirstUserActivityMoreThat";
import ReputationEqualsNew from "./ReputationConditionsWrapper/conditions/ReputationEquals";
import ReputationUpperNew from "./ReputationConditionsWrapper/conditions/ReputationUpper";
import ReputationLowerNew from "./ReputationConditionsWrapper/conditions/ReputationLower";
import ReactionsConditionsWrapper from "./ReactionsConditionsWrapper";
import ReactionAdd from "./ReactionsConditionsWrapper/conditions/ReactionAdd";
import ReactionRemove from "./ReactionsConditionsWrapper/conditions/ReactionRemove";
import ReactionCount from "./ReactionsConditionsWrapper/conditions/ReactionCount";
import RequestUserType from "./RequestUserType";
import CheckRegexpPlaceholder from "./CheckRegexpPlaceholder";
import MessageTextSubstringMatchNew from "./MessageTextSubstringMatch";
import HasDialog from "./HasDialog";
import MessageLang from "./MessageLang";
import MessageToxic from "./MessageToxic";
import HasAchievement from "./HasAchievement";
import MessageForwardType from "./MessageForwardType";
import HasSimilarMessage from "./HasSimilarMessage";
import HasCaptchaQuest from "./HasCaptchaQuest";
import RefConditionsWrapper from "./RefConditionsWrapper";
import RefEquals from "./RefConditionsWrapper/conditions/RefEquals";
import RefLower from "./RefConditionsWrapper/conditions/RefLower";
import RefUpper from "./RefConditionsWrapper/conditions/RefUpper";
import UserLang from "./UserLang";

import WarnCountConditionsWrapper from "./WarnCountConditionsWrapper";
import WarnCountUpper from "./WarnCountConditionsWrapper/conditions/WarnCountUpper";
import WarnCountEquals from "./WarnCountConditionsWrapper/conditions/WarnCountEquals";
import WarnCountLower from "./WarnCountConditionsWrapper/conditions/WarnCountLower";

import WarnCountToBan from "./WarnCountToBan";

import BanLevelCountConditionsWrapper from "./BanLevelCountConditionsWrapper";
import BanLevelCountUpper from "./BanLevelCountConditionsWrapper/conditions/BanLevelCountUpper";
import BanLevelCountEquals from "./BanLevelCountConditionsWrapper/conditions/BanLevelCountEquals";
import BanLevelCountLower from "./BanLevelCountConditionsWrapper/conditions/BanLevelCountLower";

import Expression from "./Expression";

import HttpRequestConditionsWrapper from "@/components/TriggerSetup/conditions/HttpRequestConditionsWrapper";
import HttpRequestContains from "./HttpRequestConditionsWrapper/conditions/HttpRequestContains";
import HttpRequestEquals from "./HttpRequestConditionsWrapper/conditions/HttpRequestEquals";

export function getTriggerConditions(): Record<string, EntityData> {
  return cloneDeep(chain([
        MessageTextEndWithNew,
        MessageTextFullMatchNew,
        MessageTextStartWithNew,
        MessageTextSubstringMatchNew,

        MessageTextRegExpNew,
        MessageMentionEntityTypeNew,
        UserLoginRegExpNew,
        UserNameRegExpNew,
        MessagePhoneNumberEntityTypeNew,
        MessageTextMentionEntityTypeNew,

        XpEqualsNew,
        XpLowerNew,
        XpUpperNew,

        MessageCountLowerNew,
        MessageCountUpperNew,

        DaysWithMessagesLowerNew,
        DaysWithMessagesUpperNew,

        MessageTextMaxLenNew,
        MessageTextMinLenNew,

        MessageWordFullMatchNew,
        MessageWordEndWithNew,
        MessageWordStartWithNew,

        UserLoginMinLenNew,
        UserLoginMaxLenNew,

        UserNameMinLenNew,
        UserNameMaxLenNew,

        RankEqualsNew,
        RankLowerNew,
        RankUpperNew,

        ReputationEqualsNew,
        ReputationUpperNew,
        ReputationLowerNew,

        ActionPointsEqualsNew,
        ActionPointsLowerNew,
        ActionPointsUpperNew,

        WorkDatesNew,
        WorkMonthDaysNew,
        WorkDaysNew,
        WorkTimeNew,
        WorkWeekDaysNew,

        FirstUserMessageTimeMoreThatNew,
        FirstUserMessageTimeLowerThatNew,

        FirstUserEnterTimeLowerThatNew,
        FirstUserEnterTimeMoreThatNew,

        LastUserEnterTimeLowerThatNew,
        LastUserEnterTimeMoreThatNew,

        FirstUserActivityLowerThatNew,
        FirstUserActivityMoreThatNew,

        MessageTypeNew,

        UserInformationNew,

        ThreadIdNew,
        CallerTypeNew,
        MessageReplyTypeNew,
        CheckBindedChannelNew,
        CheckBoostNew,
        CheckPremiumNew,

        ReactionRemove,
        ReactionCount,
        ReactionAdd,

        RequestUserType,

        CheckRegexpPlaceholder,

        HasDialog,

        MessageLang,
        MessageToxic,

        HasAchievement,
        HasSimilarMessage,
        HasCaptchaQuest,

        MessageForwardType,

        RefEquals,
        RefLower,
        RefUpper,

        WarnCountUpper,
        WarnCountEquals,
        WarnCountLower,

        WarnCountToBan,

        BanLevelCountUpper,
        BanLevelCountEquals,
        BanLevelCountLower,

        Expression,

        HttpRequestContains,
        HttpRequestEquals,

    // not set in any group (conditionsGroupsTypes), so isn`t visible
        UserLang,
        //
      ]
  ))
      .keyBy('structure.condition_type')
      .value();
}

export const newConditionWrappers = [
  MessageTextConditionsWrapperNew,
  RegexpConditionsWrapperNew,
  XpConditionsWrapperNew,
  MessageCountConditionsWrapperNew,
  DaysWithMessagesConditionsWrapperNew,
  MessageTextLenConditionsWrapperNew,
  MessageWordConditionsWrapperNew,
  UserLoginConditionsWrapperNew,
  UserNameConditionsWrapperNew,
  RankConditionsWrapperNew,
  ReputationConditionsWrapperNew,
  ActionPointsConditionsWrapperNew,
  FirstUserMessageTimeConditionsWrapperNew,
  FirstUserEnterTimeConditionsWrapperNew,
  LastUserEnterTimeConditionsWrapperNew,
  FirstUserActivityConditionsWrapperNew,
  ReactionsConditionsWrapper,
  RefConditionsWrapper,

  WarnCountConditionsWrapper,
  BanLevelCountConditionsWrapper,

  HttpRequestConditionsWrapper
]

export const conditionsGroupsTypes: Record<string, Array<EntityData>> = {
  reactionConditions: [
    ReactionsConditionsWrapper,
    ReactionCount
  ],
  messageConditions: [
    MessageTextConditionsWrapperNew,
    MessageWordConditionsWrapperNew,
    MessageLang,
    MessageToxic,
    ...store.getters.isChatSet && store.state.chatState!.chat!.version >= 500 ? [
      HasSimilarMessage
    ] : [],
      Expression,
  ],
  messagePropertiesConditions: [
    MessageTypeNew,
    MessageForwardType,
    MessageReplyTypeNew,
    MessageTextLenConditionsWrapperNew,
  ],
  userConditions: [
    UserLoginConditionsWrapperNew,
    UserNameConditionsWrapperNew,
    UserInformationNew,
    HasDialog,
    RefConditionsWrapper,
  ],
  userRankAndPointsConditions: [
    RankConditionsWrapperNew,
    ActionPointsConditionsWrapperNew,
    ReputationConditionsWrapperNew,
    XpConditionsWrapperNew,
    HasAchievement
  ],
  dataConditions: [
    WorkDaysNew,
    WorkWeekDaysNew,
    WorkDatesNew,
    WorkMonthDaysNew,
    WorkTimeNew,
  ],
  userActionsConditions: [
    MessageCountConditionsWrapperNew,
    DaysWithMessagesConditionsWrapperNew,
    FirstUserEnterTimeConditionsWrapperNew,
    LastUserEnterTimeConditionsWrapperNew,
    FirstUserActivityConditionsWrapperNew,
    FirstUserMessageTimeConditionsWrapperNew,

      WarnCountConditionsWrapper,
      WarnCountToBan,
      BanLevelCountConditionsWrapper
  ],
  otherConditions: [
    RegexpConditionsWrapperNew,
    CheckRegexpPlaceholder,
    ThreadIdNew,
    CallerTypeNew,
    RequestUserType,
    CheckBindedChannelNew,
    CheckBoostNew,
    CheckPremiumNew,
    HasCaptchaQuest,

    HttpRequestConditionsWrapper
  ]
}
