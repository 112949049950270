





















import { ConditionSetupView } from '../../logic/mixins/condition-setup-view.mixin'
import ConditionWrapperMixin from "@/components/TriggerSetup/conditions/ConditionWrapperMixin";

import { Component, Mixins } from 'vue-property-decorator'

@Component
export default class WarnCountConditionsWrapper extends Mixins(ConditionSetupView, ConditionWrapperMixin) {
}
