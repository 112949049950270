import { EntityData } from "@/components/TriggerSetup/logic/types/entity-data.type";
import { newBaseCondition } from "@/components/TriggerSetup/logic/structures/base-condition.structure";
import {
  ConditionsType,
  ConditionZone,
  TriggerCondition
} from "@/components/TriggerSetup/logic/types/types";
import { entityDataTitleKeyFields } from "@/components/TriggerSetup/logic/helpers";
import CheckRegexpPlaceholderSetupView
  from "@/components/TriggerSetup/conditions/CheckRegexpPlaceholder/CheckRegexpPlaceholderSetupView.vue";
import { CheckRegexpEqualityEnum } from "./types";

const condition: EntityData = {
  'setupView': CheckRegexpPlaceholderSetupView,
  'structure': {
    'condition_type': ConditionsType.CheckRegexpPlaceholder,
    ...newBaseCondition,
    value: [ CheckRegexpEqualityEnum.any ]
  } as TriggerCondition,
  ...entityDataTitleKeyFields(ConditionsType.CheckRegexpPlaceholder, 'trigger_condition_'),
  extraProps: {
    possibleConditionZone: [ ConditionZone.Message, ConditionZone.ReplyMessage, ConditionZone.RequestMessage, ConditionZone.ReactionMessage ]
  }
}

export default condition
