import { EntityData } from "@/components/TriggerSetup/logic/types/entity-data.type";
import { newBaseCondition } from "@/components/TriggerSetup/logic/structures/base-condition.structure";
import HasDialogConditionSetupView
  from "@/components/TriggerSetup/conditions/HasDialog/HasDialogConditionSetupView.vue";
import { ConditionsType, ConditionZone, TriggerCondition } from "@/components/TriggerSetup/logic/types/types";
import { entityDataTitleKeyFields } from "@/components/TriggerSetup/logic/helpers";
import { ULTIMATE_TAG } from "@/includes/constants";
import store from "@/store/store";

const condition: EntityData = {
  'setupView': HasDialogConditionSetupView,
  'structure': {
    'condition_type': ConditionsType.HasCaptchaQuest,
    ...newBaseCondition,
    value: [ '' ]
  } as TriggerCondition,
  ...entityDataTitleKeyFields(ConditionsType.HasCaptchaQuest, 'trigger_condition_'),
  extraProps: {
    possibleConditionZone: [ ConditionZone.Message, ConditionZone.ReplyMessage, ConditionZone.RequestActor,
      ConditionZone.ReactionActor, ConditionZone.ReactionMessage ]
  },
  limited: () => store.getters.getHaveLicense && store.getters.isChatLicenseExists ? null : ULTIMATE_TAG
}

export default condition
