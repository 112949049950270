import { EntityData } from "@/components/TriggerSetup/logic/types/entity-data.type";
import { newBaseCondition } from "@/components/TriggerSetup/logic/structures/base-condition.structure";
import { ConditionsType, ConditionZone, TriggerCondition } from "@/components/TriggerSetup/logic/types/types";
import { entityDataTitleKeyFields } from "@/components/TriggerSetup/logic/helpers";
import RequestUserTypeSetupView
  from "@/components/TriggerSetup/conditions/RequestUserType/RequestUserTypeSetupView.vue";

const condition: EntityData = {
  'setupView': RequestUserTypeSetupView,
  'structure': {
    'condition_type': ConditionsType.RequestUserType,
    ...newBaseCondition,
    value: [ '' ]
  } as TriggerCondition,
  ...entityDataTitleKeyFields(ConditionsType.RequestUserType, 'trigger_condition_'),
  extraProps: {
    possibleConditionZone: [ ConditionZone.RequestActor ]
  }
}

export default condition
