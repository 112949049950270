import { EntityData } from "@/components/TriggerSetup/logic/types/entity-data.type";

import { ConditionsWrapperType } from "../../logic/types/types";
import UserNameMinLen from "./conditions/UserNameMinLen";
import UserNameConditionsWrapper from "./UserNameConditionsWrapper.vue";
import UserNameMaxLen from "./conditions/UserNameMaxLen";
import { entityDataTitleKeyFields } from "@/components/TriggerSetup/logic/helpers";

const condition: EntityData = {
  'setupView': UserNameConditionsWrapper,
  ...entityDataTitleKeyFields(ConditionsWrapperType.UserNameConditionsWrapper, 'trigger_condition_'),
  structure: UserNameMinLen.structure,
  extraProps: {
    conditions: [
      UserNameMinLen,
      UserNameMaxLen,
    ],
  }
}

export default condition
