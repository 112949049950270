var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"llm-switch-request-action-setup-view"},[_c('validation-observer',{attrs:{"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('a-modal',{attrs:{"after-close":function () { return _vm.variant = _vm.baseVariantModel(); },"ok-text":_vm.modalOkText,"ok-button-props":{
        props: {
          disabled: invalid
        }
      }},on:{"ok":_vm.okClick},model:{value:(_vm.isAddVariantModalOpen),callback:function ($$v) {_vm.isAddVariantModalOpen=$$v},expression:"isAddVariantModalOpen"}},[_c('text-input',{staticClass:"mt-0",attrs:{"setup":{
          'func': _vm.modelSetter,
          'args': {
            'model': _vm.variant.value,
            'key': 'key',
            'prefix': 'trigger_action_llm_variants_item_',
            'validation': "required"
          }
        }}}),_c('multi-message-editor-with-media-input',{staticClass:"mb-0",attrs:{"setup":{
          'func': _vm.messageEditorToPlainSetter,
          'args': {
            'model': _vm.variant.value,
            'key': 'message',
            'targetToUpload':  _vm.groupUploadTarget,
            'editorMode': _vm.MediaEditorMode.TelegramShort,
            'base-api-url': 'api.chatkeeper.app/cabinet',
            'disabled': _vm.disabled,
            'placeholders': _vm.AI_PLACEHOLDERS,
            'availableButtonsTypes': _vm.newDefaultMessageEditorButtons,
            'validation': 'required'
          },
        }}})],1)]}}])}),_c('select-input',{attrs:{"setup":{
      'func': _vm.modelSetter,
      'args': {
        model: _vm.model,
        'key': 'credential',
        'prefix': 'trigger_action_llm_',
        'options': _vm.credentialOptions,
        'validation': 'required',
        'clearable': false,
        disabled: _vm.disabled,
      }
    }}}),_c('select-input',{attrs:{"setup":{
      'func': _vm.modelSetter,
      'args': {
        model: _vm.model,
        'key': 'model',
        'prefix': 'trigger_action_llm_',
        'options': _vm.credentialProviderModelOptions(_vm.model.credential),
        'validation': 'required',
        'clearable': false,
        disabled: _vm.disabled,
      }
    }}}),_c('textarea-input',{attrs:{"setup":{
      'func': _vm.modelSetter,
      'args': {
        model: _vm.model,
        'key': 'instruction',
        'prefix': 'trigger_action_llm_',
        'validation': 'required',
        'autosize': {
          minRows: 4,
          maxRows: 8,
        }
      },
    }}}),_c('text-input',{staticClass:"mt-0",attrs:{"setup":{
      'func': _vm.modelSetter,
      'args': {
        model: _vm.model,
        'key': 'request',
        'prefix': 'trigger_action_llm_',
        'validation': 'required',
      },
    }}}),_c('number-input',{attrs:{"setup":{
      'func': _vm.modelSetter,
      'args': {
        model: _vm.model,
        'key': 'temperature',
        'prefix': 'trigger_action_llm_',
        'min': 0,
        'max': 2,
        'step': 0.1,
        disabled: _vm.disabled
      }
    }}}),_c('number-input',{attrs:{"setup":{
      'func': _vm.modelSetter,
      'args': {
        model: _vm.model,
        'key': 'maxTokens',
        'prefix': 'trigger_action_llm_',
        'min': 0,
        'max': Number.MAX_SAFE_INTEGER,
        disabled: _vm.disabled,
      }
    }}}),_c('config-field',{staticClass:"my-3 py-1",attrs:{"title":_vm.$t('field_trigger_action_llm_switch_variants_title'),"help-message":_vm.$t('field_trigger_action_llm_switch_variants_help_message'),"mini-help-message":_vm.$t('field_trigger_action_llm_switch_variants_mini_help_message'),"required":""}},[_c('a-button',{attrs:{"slot":"titleButton","size":"small","icon":"plus"},on:{"click":function($event){_vm.isAddVariantModalOpen = true}},slot:"titleButton"},[_vm._v(" "+_vm._s(_vm.$t('add'))+" ")]),_c('a-list',{attrs:{"data-source":_vm.frontendVariants},scopedSlots:_vm._u([{key:"renderItem",fn:function(item, index){return _c('a-list-item',{},[_c('a-list-item-meta',{scopedSlots:_vm._u([{key:"description",fn:function(){return [_c('div',{domProps:{"innerHTML":_vm._s(_vm.$t('trigger_action_llm_variants_item_key', [item.value.key]))}}),_c('div',{staticClass:"line-clamp-3",domProps:{"innerHTML":_vm._s(_vm.$t('trigger_action_llm_variants_item_message', [_vm.getFirstMessageText(item.value.message)]))}})]},proxy:true}],null,true)}),_c('div',{staticClass:"flex gap-2"},[_c('a-button',{staticClass:"text-warning hover:opacity-75",attrs:{"type":"link","size":"small","icon":"edit"},on:{"click":function($event){return _vm.editVariant(item)}}}),_c('a-button',{staticClass:"text-danger hover:opacity-75",attrs:{"type":"link","size":"small","icon":"delete"},on:{"click":function($event){return _vm.removeVariant(item.guid)}}})],1)],1)}}])})],1),_c('multi-message-editor-with-media-input',{attrs:{"setup":{
      'func': _vm.messageEditorToPlainSetter,
      'args': {
        'model': _vm.model,
        'key': 'notSelected',
        'prefix': 'trigger_action_llm_switch_',
        'targetToUpload':  _vm.groupUploadTarget,
        'editorMode': _vm.MediaEditorMode.TelegramShort,
        'base-api-url': 'api.chatkeeper.app/cabinet',
        'disabled': _vm.disabled,
        'placeholders': _vm.AI_PLACEHOLDERS,
        'availableButtonsTypes': _vm.newDefaultMessageEditorButtons,
        'validation': 'required'
      },
    }}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }