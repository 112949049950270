import { ConditionsWrapperType } from "../../logic/types/types";
import { EntityData } from "@/components/TriggerSetup/logic/types/entity-data.type";
import { entityDataTitleKeyFields } from "@/components/TriggerSetup/logic/helpers";
import ReactionAdd from "./conditions/ReactionAdd";
import ReactionRemove from "./conditions/ReactionRemove";
import store from "@/store/store";
import { ULTIMATE_TAG } from "@/includes/constants";

const condition: EntityData = {
  ...entityDataTitleKeyFields(ConditionsWrapperType.ReactionsConditions, 'trigger_condition_'),
  structure: ReactionAdd.structure,
  extraProps: {
    conditions: [
      ReactionAdd,
      ReactionRemove,
    ],
  },
  setupView: () => import('./ReactionsConditionsWrapper.vue'),
  limited: () => store.getters.getHaveLicense && store.getters.isChatLicenseExists ? null : ULTIMATE_TAG
}

export default condition
