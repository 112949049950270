import { EntityData } from "@/components/TriggerSetup/logic/types/entity-data.type";
import { newBaseCondition } from "@/components/TriggerSetup/logic/structures/base-condition.structure";
import {
  ConditionsType,
  ConditionZone,
  TriggerCondition
} from "@/components/TriggerSetup/logic/types/types";
import { entityDataTitleKeyFields } from "@/components/TriggerSetup/logic/helpers";
import LastUserEnterTimeLowerThatSetupView from "./LastUserEnterTimeLowerThatSetupView.vue";

const condition: EntityData = {
  'setupView': LastUserEnterTimeLowerThatSetupView,
  'structure': {
    'condition_type': ConditionsType.LastUserEnterTimeLowerThat,
    ...newBaseCondition,
  } as TriggerCondition,
  ...entityDataTitleKeyFields(ConditionsType.LastUserEnterTimeLowerThat, 'trigger_condition_'),
  extraProps: {
    possibleConditionZone: [ ConditionZone.Message, ConditionZone.ReplyMessage, ConditionZone.RequestActor, ConditionZone.ReactionActor,
      ConditionZone.ReactionMessage ]
  }
}

export default condition
