import { ConditionsWrapperType } from "../../logic/types/types";
import MessageTextRegExp from "./conditions/MessageTextRegExp";
import MessageMentionEntityType from "./conditions/MessageMentionEntityType"
import UserLoginRegExp from "./conditions/UserLoginRegExp"
import UserNameRegExp from "./conditions/UserNameRegExp"
import MessagePhoneNumberEntityType from "../MessagePhoneNumberEntityType";
import MessageTextMentionEntityType from './conditions/MessageTextMentionEntityType';
import RegexpConditionsWrapper from "./RegexpConditionsWrapper.vue";
import { entityDataTitleKeyFields } from "@/components/TriggerSetup/logic/helpers";
import { EntityData } from "@/components/TriggerSetup/logic/types/entity-data.type";

const condition: EntityData = {
  setupView: RegexpConditionsWrapper,
  ...entityDataTitleKeyFields(ConditionsWrapperType.RegexpConditionsWrapper, 'trigger_condition_'),
  structure: MessageTextRegExp.structure,
  extraProps: {
    conditions: [
      MessageTextRegExp,
      MessageMentionEntityType,
      UserLoginRegExp,
      UserNameRegExp,
      MessagePhoneNumberEntityType,
      MessageTextMentionEntityType,
    ],
  }
}

export default condition
