import { ConditionsWrapperType } from "../../logic/types/types";
import MessageCountConditionsWrapper from "./MessageCountConditionsWrapper.vue";
import store from "@/store/store";
import MessageCountLower from "./conditions/MessageCountLower";
import MessageCountUpper from "./conditions/MessageCountUpper";

import { ADVANCED_TAG } from "@/includes/constants";
import { entityDataTitleKeyFields } from "@/components/TriggerSetup/logic/helpers";
import { EntityData } from "@/components/TriggerSetup/logic/types/entity-data.type";

const condition: EntityData = {
  setupView: MessageCountConditionsWrapper,
  ...entityDataTitleKeyFields(ConditionsWrapperType.MessageCountConditionsWrapper, 'trigger_condition_'),
  structure: MessageCountLower.structure,
  extraProps: {
    conditions: [
      MessageCountLower,
      MessageCountUpper,
    ],
  },
  limited: () => {
    if (store.getters.isChatSet && store.getters.isChatLicenseExists && store.getters.isChatAtLeastAdvanced) {
      return null
    } else {
      return ADVANCED_TAG
    }
  }
}

export default condition
