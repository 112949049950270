var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"llm-convert-request-action-setup-view"},[_c('select-input',{attrs:{"setup":{
      'func': _vm.modelSetter,
      'args': {
        model: _vm.model,
        'key': 'credential',
        'prefix': 'trigger_action_llm_',
        'options': _vm.credentialOptions,
        'validation': 'required',
        'clearable': false,
        disabled: _vm.disabled,
      }
    }}}),_c('select-input',{attrs:{"setup":{
      'func': _vm.modelSetter,
      'args': {
        model: _vm.model,
        'key': 'model',
        'prefix': 'trigger_action_llm_',
        'options': _vm.credentialProviderModelOptions(_vm.model.credential),
        'validation': 'required',
        'clearable': false,
        disabled: _vm.disabled,
      }
    }}}),_c('textarea-input',{attrs:{"setup":{
      'func': _vm.modelSetter,
      'args': {
        model: _vm.model,
        'key': 'instruction',
        'prefix': 'trigger_action_llm_',
        'validation': 'required',
        'autosize': {
          minRows: 4,
          maxRows: 8,
        }
      },
    }}}),_c('text-input',{attrs:{"setup":{
      'func': _vm.modelSetter,
      'args': {
        model: _vm.model,
        'key': 'request',
        'prefix': 'trigger_action_llm_',
        'validation': 'required',
      },
    }}}),_c('number-input',{attrs:{"setup":{
      'func': _vm.modelSetter,
      'args': {
        model: _vm.model,
        'key': 'temperature',
        'prefix': 'trigger_action_llm_',
        'min': 0,
        'max': 2,
        'step': 0.1,
        disabled: _vm.disabled
      }
    }}}),_c('number-input',{attrs:{"setup":{
      'func': _vm.modelSetter,
      'args': {
        model: _vm.model,
        'key': 'maxTokens',
        'prefix': 'trigger_action_llm_',
        'min': 0,
        'max': 2,
        disabled: _vm.disabled,
      }
    }}}),_c('multi-message-editor-with-media-input',{attrs:{"setup":{
      'func': _vm.messageEditorToPlainSetter,
      'args': {
        model: _vm.model,
        'key': 'message',
        'prefix': 'trigger_action_llm_',
        'targetToUpload':  _vm.groupUploadTarget,
        'editorMode': _vm.MediaEditorMode.TelegramShort,
        'base-api-url': 'api.chatkeeper.app/cabinet',
        'disabled': _vm.disabled,
        'placeholders': _vm.AI_PLACEHOLDERS,
        'availableButtonsTypes': _vm.newDefaultMessageEditorButtons,
      },
    }}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }