import { NewTriggerCondition } from "@/components/TriggerSetup/logic/types/types";

export const newBaseCondition: Omit<NewTriggerCondition, 'condition_type'> = {
  value         : [],
  filters       : [ "Trim", "CaseInsensitive" ],
  caller        : "Anybody",
  messageType   : "Any",
  invert        : false,
  placeholder   : null,
  condition_zone: null,
  version       : 'v2'
}
